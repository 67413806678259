import * as React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { normalizedData } from "@utils";
import Seo from "@components/seo";
import Layout from "@layout";
import Header from "@layout/header/layout-01";
import Footer from "@layout/footer/layout-01";
import PageHeader from "@containers/page-header/layout-03";
import ClientFacts from "@containers/language-translation/client-facts/layout-02";
import Results from "@containers/language-translation/client-facts";
import OurSolution from "@containers/language-translation/our-solution";

const CaseAmway = ({ location, data }) => {
  const content = normalizedData(data?.page.content || []);
  const globalContent = normalizedData(data?.allGeneral.nodes || []);

  return (
    <Layout location={location}>
      <Seo
        title="Case study - Amway"
        description="Amway is one of the world's leading direct selling companies operating in over 100 countries around the world. The company was founded in 1959, in Ada, Michigan, United States. In 1987, Amway Thailand was founded with being a member of Thai Direct Selling Association (TDSA) since 1988."
      />
      <Header
        data={{
          ...globalContent["header"],
          ...globalContent["menu"],
        }}
      />
      <main className="site-wrapper-reveal">
        <PageHeader layout={10} data={content["amway-header-section"]} />
        <ClientFacts data={content["amway-client-challenges"]} />
        <OurSolution layout={7} data={content["amway-solutions"]} />
        <Results data={content["amway-results"]} />
      </main>
      <Footer data={{ ...data.site.siteMetadata }} />
    </Layout>
  );
};

export const query = graphql`
  query caseCaseAmwayPageQuery {
    allGeneral {
      nodes {
        section
        ...HeaderOne
      }
    }
    site {
      ...Site
    }
    page(title: { eq: "use-cases" }, pageType: { eq: "innerpage" }) {
      content {
        ...PageContent
      }
    }
  }
`;

CaseAmway.propTypes = {
  location: PropTypes.shape({}),
  data: PropTypes.shape({
    allGeneral: PropTypes.shape({
      nodes: PropTypes.arrayOf(PropTypes.shape({})),
    }),
    site: PropTypes.shape({
      siteMetadata: PropTypes.shape({
        contact: PropTypes.shape({}),
      }),
    }),
    page: PropTypes.shape({
      content: PropTypes.arrayOf(PropTypes.shape({})),
    }),
  }),
};

export default CaseAmway;
